import { graphql } from "gatsby"
import React from "react"
import CustomFonts from "@rohs/gatsby-theme-intro/src/components/custom-fonts/custom-fonts"
import Footer from "@rohs/gatsby-theme-intro/src/components/footer/footer"
import Header from "@rohs/gatsby-theme-intro/src/components/header/header"
import SEO from "@rohs/gatsby-theme-intro/src/components/seo/seo"
import Sidebar from "@rohs/gatsby-theme-intro/src/components/sidebar/sidebar"
import StructuredData from "@rohs/gatsby-theme-intro/src/components/structured-data/structured-data"
import "@rohs/gatsby-theme-intro/src/styles/style.css"

const ImpressumPage = ({ data }) => {
  const { profile, social } = data

  return (
    <div className="antialiased bg-back leading-normal font-text text-front">
      <SEO />
      <StructuredData profile={profile} social={social.nodes} />
      <CustomFonts />

      <Header initials={profile.initials} />

      <div className="md:max-w-screen-sm lg:max-w-screen-xl mx-auto px-4 flex flex-wrap pt-4 my-8">
        <Sidebar profile={profile} social={social.nodes} />

        <main className="lg:w-2/3 lg:pl-8 xl:pl-12">
        <div className="mb-8">
            <h1 className="font-header font-light text-front text-5xl leading-none mb-4">Impressum</h1>
            <p className="font-text text-sm leading-normal whitespace-pre-line">
              <address>
                <strong>Florian Jörg IT</strong><br />
                <strong>Florian Matthias Jörg</strong><br />
                Egelsdorf 211<br />
                8261 Sinabelkirchen<br /><br />
                Telefon: <a href="tel:+4366488865127">+43 664 88865127</a><br />
                E-Mail: <a href="mailto:hello@fjoerg.com">hello@fjoerg.com</a>
              </address>
            </p>
            <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Vollständiger Firmenname</h5>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Florian Matthias Jörg</p>
            <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Ort der Gewerbeberechtigung</h5>
            <p className="font-text text-sm leading-normal whitespace-pre-line"> Egelsdorf 211<br />8261 Sinabelkirchen<br />Österreich</p>
            <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Firmenbuchgericht</h5>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Landesgericht für Zivilrechtssachen Graz</p>
            <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Geschäftsführung/Juristische Person</h5>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Florian Matthias Jörg</p>
            <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Fotorechte</h5>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Sämtliche Aufnahmen © Florian Jörg<br /></p>
             <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Unternehmensgegenstand</h5>
             <p className="font-text text-sm leading-normal whitespace-pre-line">Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik</p>
             <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Kammer/Berufsverband-Zugehörigkeit(en)</h5>
             <p className="font-text text-sm leading-normal whitespace-pre-line">WKO</p>
             <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Aufsichtsbehörde</h5>
             <p className="font-text text-sm leading-normal whitespace-pre-line">Bezirkshauptmannschaft Weiz</p>
             <h5 className="font-header font-semibold text-front text-sm uppercase mt-3">Gewerbeordnung</h5>
             <p className="font-text text-sm leading-normal whitespace-pre-line">Anwendbare gewerbe- oder berufsrechtliche Vorschriften: Gewerbeordnung 1994, abrufbar unter <a href="http://www.ris.bka.gv.at" target="_blank" rel="noreferrer">http://www.ris.bka.gv.at</a></p>
          </div>
          <hr />
          <div className="mt-8">
            <h2 className="font-header font-light text-front text-2xl leading-none my-4">Inhalt des Onlineangebotes</h2>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
            <h2 className="font-header font-light text-front text-2xl leading-none my-4">Verweise und Links</h2>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Bei direkten oder indirekten Verweisen auf fremde Internetseiten (Links), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller gelinkten/verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichtete Gästebücher, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>
            <h2 className="font-header font-light text-front text-2xl leading-none my-4">Urheber- und Kennzeichenrecht</h2>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.</p>
            <h2 className="font-header font-light text-front text-2xl leading-none my-4">Datenschutz</h2>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (E-Mail-Adressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist - soweit technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie E-Mail-Adressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von so genannten Spam-Mails bei Verstößen gegen dieses Verbot sind ausdrücklich vorbehalten.</p>
            <h2 className="font-header font-light text-front text-2xl leading-none my-4">Rechtswirksamkeit dieses Haftungsausschlusses</h2>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p>
            <h2 className="font-header font-light text-front text-2xl leading-none my-4">Online-Streitbeilegung</h2>
            <p className="font-text text-sm leading-normal whitespace-pre-line">Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="http://ec.europa.eu/odr/" target="_blank" rel="noreferrer">http://ec.europa.eu/odr/</a> finden.</p>
          </div>
        </main>
      </div>

      <Footer
        name={profile.name}
      />
    </div>
  )
}

export default ImpressumPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        showThemeLogo
        formspreeEndpoint
      }
    }
    profile: profileYaml {
      ...ProfileFragment
    }
    social: allSocialYaml(filter: { url: { ne: null } }) {
      nodes {
        ...SocialFragment
      }
    }
    history: allWorkHistoryYaml {
      nodes {
        ...WorkHistoryFragment
      }
    }
    projects: allProjectsYaml {
      nodes {
        ...ProjectFragment
      }
    }
  }
`
